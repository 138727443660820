export default {
  name: 'FeaturedItemsPositionSelector',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: 'tl' // Top - left
    }
  },
  data() {
    return {
      selectedPosition: this.value,
      positions: ['tl', 'tc', 'tr', 'ml', 'mc', 'mr', 'bl', 'bc', 'br']
    }
  },
  watch: {
    value(value) {
      this.selectedPosition = value
    },
    selectedPosition(value) {
      this.$emit('change', value)
    }
  }
}
