export default {
  name: 'FeaturedItemsPreview',
  props: {
    backgroundColor: {
      type: String,
      default: '#FFF'
    },
    backgroundImage: {
      type: String,
      default: null
    },
    backgroundSize: {
      type: String,
      default: 'auto'
    },
    fontColor: {
      type: String,
      default: '#000'
    },
    fontFamilySubTitle: {
      type: String,
      default: 'sans-serif'
    },
    fontFamilyTitle: {
      type: String,
      default: 'sans-serif'
    },
    fontSize: {
      type: String,
      default: 'm'
    },
    labelActionButton: {
      type: String,
      default: null
    },
    position: {
      type: String,
      default: 'tl'
    },
    subtitle: {
      type: String,
      default: 'Descripción del item'
    },
    title: {
      type: String,
      default: 'Título'
    }
  },
  computed: {
    /**
     * Establece las clases css que el "content"
     * poseerá
     *
     * @return {String}
     */
    cssContent() {
      return {
        [this.position]: true
      }
    },
    /**
     * Establece las clases css que el "title"
     * poseerá
     *
     * @return {String}
     */
    cssTitle() {
      return {
        'featured-items-preview__title': true,
        [this.fontSize]: true
      }
    },
    /**
     * Establece las clases css que el "subtitle"
     * poseerá
     *
     * @return {String}
     */
    cssSubTitle() {
      return {
        'featured-items-preview__subtitle': true,
        [this.fontSize]: true
      }
    },
    /**
     * Establece los estilos que el "title"
     * poseerá
     *
     * @return {String}
     */
    stylesTitle() {
      return `font-family:'${this.fontFamilyTitle}' !important; color:${this.fontColor} !important`
    },
    /**
     * Establece los estilos que el "subtitle"
     * poseerá
     *
     * @return {String}
     */
    stylesSubTitle() {
      return `font-family:'${this.fontFamilySubTitle}' !important; color:${this.fontColor} !important`
    },
    /**
     * Establece los estilos que el "wrapper"
     * poseerá
     *
     * @return {String}
     */
    stylesWrapper() {
      return `background-color:${this.backgroundColor} !important;background-image:url(${this.backgroundImage}) !important;background-size:${this.backgroundSize} !important;`
    }
  }
}
