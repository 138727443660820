// Utils
import { isNil } from 'lodash'

export default {
  name: 'GoogleFontsSelector',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    fonts: {
      type: Array,
      default() {
        return [
          // Bolds
          'Anton',
          'Permanent Marker',
          // Natural
          'Architects Daughter',
          'Dancing Script',
          'Indie Flower',
          // Funny
          'Grandstander',
          'Lobster',
          'Pacifico',
          'Fredoka One',
          // Regular
          'Montserrat',
          'Oswald',
          'Merriweather',
          'Work Sans'
        ]
      }
    },
    label: {
      type: String,
      default: 'Selecciona la fuente'
    },
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedFont: this.value
    }
  },
  watch: {
    selectedFont: {
      handler(value) {
        if (!isNil(value)) {
          // Cargamos en el HEAD la fuente
          this.loadFontInHeaderTag(value)
          // Lanzamos evento de cambio
          this.$emit('change', value)
        }
      },
      immediate: true
    },
    value(value) {
      this.selectedFont = value
    }
  },
  methods: {
    /**
     * Carga la fuente en la cabecera del documento HTML
     *
     * @param {string} value - fuente a cargar
     */
    loadFontInHeaderTag(value) {
      if (!isNil(value)) {
        // Sustituimos los espacios por "+"
        const replacedValue = value.replace(/\s/g, '+')
        const link = document.createElement('link')

        // Atributos nueva etiqueta
        link.href = `https://fonts.googleapis.com/css2?display=swap&family=${replacedValue}`
        link.rel = 'stylesheet'

        // Añadimos nueva etiqueta a la cabecera
        document.head.appendChild(link)
      }
    }
  }
}
