// Request
import { doRequest } from '@/utils/requestApi'
// Services
import { getCTAByFeaturedItemId } from '@/addons/cta/services/cta'
// Utils
import { get, isNil } from 'lodash'

const moment = require('moment')

/**
 * Get featured items by placeId
 *
 * @param {string} id - UID place
 */
export async function getEveryFeaturedItemsByPlaceId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/featured-item/place/${id}`
  })

  // Obtenemos solo los elementos que
  // están activados o coincidentes en el
  // filtro de fecha y hora
  const isEnabledItem = (item) => {
    const enabledItem = get(item, 'enabled', true)
    const dateFrom = !isNil(item.dateFrom) ? item.dateFrom : null
    const dateTo = !isNil(item.dateTo) ? item.dateTo : null
    const timeFrom = get(item, 'timeFrom', null)
    const timeTo = get(item, 'timeTo', null)
    const everyIsNull = isNil(dateFrom) && isNil(dateTo) && isNil(timeFrom) && isNil(timeTo)
    let withInDate = false
    let withInTime = false

    if (!enabledItem) {
      return false
    }

    if (enabledItem && everyIsNull) {
      return true
    }

    if (everyIsNull) {
      return true
    }

    // Chequeamos las fechas
    if (isNil(dateFrom) && isNil(dateTo)) {
      withInDate = true
    } else if (!isNil(dateFrom) || !isNil(dateTo)) {
      const momentDateFrom = isNil(dateFrom) ? moment('01/01/2000', 'DD/MM/YYYY') : dateFrom
      const momentDateTo = isNil(dateTo) ? moment('01/01/2100', 'DD/MM/YYYY') : dateTo

      withInDate = moment().isBetween(momentDateFrom, momentDateTo)
    }

    // Chequeamos las horas
    if (isNil(timeFrom) && isNil(timeTo)) {
      withInTime = true
    } else if (!isNil(timeFrom) || !isNil(timeTo)) {
      const timeFromInDate = moment().set({
        hour: Number.parseInt(get(timeFrom, 'HH', 0), 10),
        minute: Number.parseInt(get(timeFrom, 'mm', 0), 10)
      })
      const timeToInDate = moment().set({
        hour: Number.parseInt(get(timeTo, 'HH', 0), 10),
        minute: Number.parseInt(get(timeTo, 'mm', 0), 10)
      })

      // Si la hora "hasta" es menor a la hora "desde"
      // sumamos un día a la fecha "hasta"
      if (Number.parseInt(get(timeTo, 'HH', 0), 10) < Number.parseInt(get(timeFrom, 'HH', 0), 10)) {
        timeToInDate.add(1, 'days')
      }

      withInTime = moment().isBetween(timeFromInDate, timeToInDate)
    }

    return withInDate && withInTime
  }

  // Obtenemos algunos datos mas para usar en la vista
  return Promise.all(
    result.data.map(async (item) => {
      // Posible CTA asociado
      let cta = null
      try {
        cta = await getCTAByFeaturedItemId(item.id)
      } catch {
        cta = null
      }

      return {
        ...item,
        firstCharTitle: typeof item.title === 'string' ? item.title.charAt(0).toUpperCase() : 'X',
        isPublished: isEnabledItem(item),
        cta
      }
    })
  )
}
/**
 * Create new featured item
 *
 * @param {Object} data - featured item data
 */
export async function createFeaturedItem(data, id = null) {
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/featured-item' : `v1/featured-item/${id}`,
    data: {
      data
    }
  })

  return result.data
}
/**
 * Get featuredItem by UID
 *
 * @param {string} id - UID featuredItem in database
 */
export async function getFeaturedItemById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/featured-item/${id}`
  })

  return result.data
}
/**
 * Remove the featuredItem by id and dishes associated
 *
 * @param {string} id - UID featuredItem in database
 */
export async function deleteFeaturedItemById(id) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/featured-item/${id}`
  })

  return result.data
}
/**
 * Update featuredItem in database
 *
 * @param {Object} data - featuredItem data
 */
export async function updateFeaturedItemById(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/featured-item/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}
