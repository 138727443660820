// Constants
import { FEATURED_ITEMS_WHEN_SHOW } from '../../../constants'
// Components
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
import VueTimepicker from 'vue2-timepicker'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import formMixin from '@/mixins/formMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { getFeaturedItemById, updateFeaturedItemById } from '../../../services/featuredItem'
// Utils
import { isNil, get, set } from 'lodash'
import { isDateFormat } from '@/utils'
import { formatDate } from '@/filters'

export default {
  name: 'FeaturedItemsDetailOptions',
  components: {
    CardContainerElement,
    FormButtons,
    VueTimepicker
  },
  mixins: [formMixin, validationMixin, uiMixin],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // Form
      formFields: {
        dateFrom: null,
        dateTo: null,
        enabled: true,
        timeFrom: null,
        timeTo: null,
        whenShow: FEATURED_ITEMS_WHEN_SHOW.always.id
      },
      formFieldsValidations: {
        dateFrom: {
          dateFormat: 'Formato incorrecto de fecha'
        },
        dateTo: {
          dateFormat: 'Formato incorrecto de fecha'
        }
      },
      // Others
      featuredItemData: null,
      filterDateFromMenu: false,
      filterDateToMenu: false,
      filterDateFromFormatted: null,
      filterDateToFormatted: null,
      processingRequest: true,
      whenShowValues: Object.values(FEATURED_ITEMS_WHEN_SHOW)
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * Opciones para el "v-date-picker"
     *
     * @return {object}
     */
    vDatePickerOptions() {
      const today = new Date()
      const minDate = new Date()

      minDate.setDate(today.getDate())

      return {
        color: 'secondary',
        'first-day-of-week': 1,
        min: formatDate(minDate, 'YYYY-MM-DD'),
        'no-title': true,
        scrollable: true
      }
    }
  },
  watch: {
    formFields: {
      handler(value) {
        const dateFrom = get(value, 'dateFrom', null)
        const dateTo = get(value, 'dateTo', null)

        this.filterDateFromFormatted = !isNil(dateFrom)
          ? `${formatDate(dateFrom, 'DD/MM/YYYY')}`
          : null
        this.filterDateToFormatted = !isNil(dateTo) ? `${formatDate(dateTo, 'DD/MM/YYYY')}` : null
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Cuando pulsamos sobre el icono "clear" en el
     * input de los date-picker
     *
     * @param {string} field - ID field from formFields to process
     */
    handleClearClicked(field) {
      set(this.formFields, field, null)
    },
    /**
     * Obtenemos/establecemos todos los datos necesarios
     * del componente
     */
    async getEveryNeededData() {
      try {
        // loading
        this.processingRequest = true
        // Obtenemos datos del destacado
        this.featuredItemData = await getFeaturedItemById(this.id)
        // Establecemos los datos del formulario
        this.setFormFieldsValues(this.featuredItemData)
      } catch (error) {
        // Error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
        // Regresamos al listado
        this.routerPushTo({
          name: 'featuredItems'
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Inicializamos los campos del formulario
     *
     * @param {object} data - datos del documento obtenido
     */
    async setFormFieldsValues(data) {
      this.formFields = {
        dateFrom: formatDate(get(data, 'dateFrom', null), 'YYYY-MM-DD'),
        dateTo: formatDate(get(data, 'dateTo', null), 'YYYY-MM-DD'),
        enabled: isNil(get(data, 'enabled', true)) ? true : get(data, 'enabled', true),
        whenShow: get(data, 'whenShow', FEATURED_ITEMS_WHEN_SHOW.always.id),
        timeFrom: get(data, 'timeFrom', null),
        timeTo: get(data, 'timeTo', null)
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Parseamos filtro de fecha (desde)
      let dateFrom = null
      if (!isNil(this.formFields.dateFrom)) {
        const dateFromParts = this.formFields.dateFrom.split('-')
        dateFrom = Date.UTC(dateFromParts[0], dateFromParts[1] - 1, dateFromParts[2], 0, 0, 0)
      }
      // Parseamos filtro de fecha (hasta)
      let dateTo = null
      if (!isNil(this.formFields.dateTo)) {
        const dateFromParts = this.formFields.dateTo.split('-')
        dateTo = Date.UTC(dateFromParts[0], dateFromParts[1] - 1, dateFromParts[2], 23, 59, 59)
      }
      // Parseamos filtro de hora (desde)
      const timeFromHH = get(this.formFields, 'timeFrom.HH', null)
      const timeFrom =
        isNil(timeFromHH) || timeFromHH === '' ? null : get(this.formFields, 'timeFrom', null)

      // Parseamos filtro de hora (hasta)
      const timeToHH = get(this.formFields, 'timeTo.HH', null)
      const timeTo =
        isNil(timeToHH) || timeToHH === '' ? null : get(this.formFields, 'timeTo', null)

      // Datos a salvar
      const dataToSave = {
        ...this.formFields,
        dateFrom,
        dateTo,
        placeId: this.placeData.id,
        timeFrom,
        timeTo
      }

      // Siempre es actualización
      await updateFeaturedItemById({
        ...dataToSave,
        id: this.featuredItemData.id
      })

      // Mostramos mensaje de todo OK
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      dateFrom: {
        dateFormat: (value) => {
          return isNil(value) || value === '' || isDateFormat(value, 'YYYY-MM-DD')
        }
      },
      dateTo: {
        dateFormat: (value) => {
          return isNil(value) || value === '' || isDateFormat(value, 'YYYY-MM-DD')
        }
      },
      enabled: {},
      whenShow: {},
      timeFrom: {},
      timeTo: {},
      showTitle: {}
    }
  }
}
