// Components
import CardContainerElement from '@/components/ui/CardContainerElement'
import ColorSelector from '@/components/ui/ColorSelector'
import FormButtons from '@/components/ui/FormButtons'
import GoogleFontsSelector from '@/components/ui/GoogleFontsSelector'
import FeaturedItemsPositionSelector from '../../ui/FeaturedItemsPositionSelector'
import PreviewImageUploader from '@/components/ui/PreviewImageUploader'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import formMixin from '@/mixins/formMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters } from 'vuex'
// Services
import {
  getFeaturedItemById,
  createFeaturedItem,
  updateFeaturedItemById,
  deleteFeaturedItemById
} from '../../../services/featuredItem'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'FeaturedItemsDetailDesign',
  components: {
    CardContainerElement,
    ColorSelector,
    FormButtons,
    GoogleFontsSelector,
    FeaturedItemsPositionSelector,
    PreviewImageUploader,
    VuetifyContentLoading
  },
  mixins: [formMixin, validationMixin, uiMixin],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // Form
      formFields: {
        backgroundImage: null,
        backgroundColor: '#000',
        backgroundSize: 'auto',
        fontColor: '#FFF',
        fontFamilyTitle: null,
        fontFamilySubTitle: null,
        fontSize: 'm',
        position: 'tl', // En el centro
        subtitle: 'Subtítulo de prueba',
        showTitle: true,
        title: 'Título de prueba'
      },
      formFieldsValidations: {
        title: {
          required: 'Campo obligatorio'
        }
      },
      // Others
      backgroundSizes: [
        {
          label: 'Automático',
          value: 'auto'
        },
        {
          label: 'Ajustado',
          value: 'contain'
        },
        {
          label: 'Estirado',
          value: 'cover'
        }
      ],
      featuredItemData: null,
      fontSizesLabel: ['xs', 's', 'm', 'l', 'xl'],
      maxSizeImage: {
        width: 1080,
        height: 1920
      },
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  watch: {
    formFields: {
      handler(value) {
        if (!isNil(value)) {
          // Emitimos los valores modificados
          this.$emit('changePreviewOptions', {
            backgroundColor: value.backgroundColor,
            backgroundImage: value.backgroundImage,
            backgroundSize: value.backgroundSize,
            fontColor: value.fontColor,
            fontFamilySubTitle: value.fontFamilySubTitle,
            fontFamilyTitle: value.fontFamilyTitle,
            fontSize: this.NumberToFontSize(value.fontSize),
            position: value.position,
            subtitle: value.subtitle,
            title: value.showTitle ? value.title : null
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * When the user must click on delete button
     */
    async handleDeleteButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          try {
            // Loading
            this.formProcessing = true
            // Eliminamos destacado
            const { ok } = await deleteFeaturedItemById(this.id)

            if (!ok) {
              throw new Error('Hubo un error al intentar eliminar el "destacado".')
            }

            // Regresamos al listado
            this.goToList()
          } catch (error) {
            // Error
            this.handleError(error.message)
          } finally {
            // Loading
            this.formProcessing = false
          }
        },
        actionButtonText: 'Borrar',
        text: '¿Deseas borrar este destacado?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Cuando usuario pulsado sobre el icono de "mostrar/ocultar"
     * título
     */
    handleToggleTitle() {
      this.formFields.showTitle = !this.formFields.showTitle
    },
    /**
     * Obtenemos/establecemos todos los datos necesarios
     * del componente
     */
    async getEveryNeededData() {
      try {
        if (!isNil(this.id)) {
          // loading
          this.processingRequest = true
          // Obtenemos datos del destacado
          this.featuredItemData = await getFeaturedItemById(this.id)
          // Establecemos los datos del formulario
          this.setFormFieldsValues(this.featuredItemData)
        }
      } catch (error) {
        // Error
        this.handleError(error.message)
        // Regresamos al listado
        this.goToList()
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Volvemos al listado de elementos
     */
    goToList() {
      this.routerPushTo({
        name: 'featuredItems'
      })
    },
    /**
     * Inicializamos los campos del formulario
     *
     * @param {object} data - datos del documento obtenido
     */
    async setFormFieldsValues(data) {
      this.formFields = {
        backgroundColor: get(data, 'backgroundColor', this.formFields.backgroundColor),
        backgroundImage: get(data, 'backgroundImage', this.formFields.backgroundImage),
        backgroundSize: get(data, 'backgroundSize', this.formFields.backgroundSize),
        fontFamilyTitle: get(data, 'fontFamilyTitle', this.formFields.fontFamilyTitle),
        fontFamilySubTitle: get(data, 'fontFamilySubTitle', this.formFields.fontFamilySubTitle),
        fontColor: get(data, 'fontColor', this.formFields.fontColor),
        fontSize: this.fontSizeToNumber(get(data, 'fontSize', this.formFields.fontSize)),
        position: get(data, 'position', this.formFields.position),
        showTitle: get(data, 'showTitle', this.formFields.showTitle),
        subtitle: get(data, 'subtitle', this.formFields.subtitle),
        title: get(data, 'title', this.formFields.title)
      }
    },
    /**
     * Lanzado una vez pasada la validación de los
     * campos del formulario
     */
    async afterSubmit() {
      // Datos a salvar
      const dataToSave = {
        ...this.formFields,
        fontSize: this.NumberToFontSize(this.formFields.fontSize),
        placeId: this.placeData.id
      }

      if (isNil(this.id)) {
        // Creamos
        const { featuredItem } = await createFeaturedItem(dataToSave)
        this.featuredItem = featuredItem
        // Emitimos para que se habiliten las otras opciones
        this.$emit('changeId', this.featuredItem.id)
      } else {
        // Actualizamos
        await updateFeaturedItemById({ ...dataToSave, id: this.id })
      }

      // Mostramos mensaje de todo OK
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    },
    /**
     * "Parseamos" letras a números, del tamaño de la fuente
     *
     * @param {String} value
     * @return {Number}
     */
    fontSizeToNumber(value) {
      return this.fontSizesLabel.indexOf(value) > -1 ? this.fontSizesLabel.indexOf(value) : 0
    },
    /**
     * "Parseamos" letras a números, del tamaño de la fuente
     *
     * @param {Number} value
     * @return {String}
     */
    NumberToFontSize(value) {
      return this.fontSizesLabel[value] || this.fontSizesLabel[0]
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      backgroundColor: {},
      backgroundImage: {},
      backgroundSize: {},
      enabled: {},
      fontColor: {},
      fontFamilySubTitle: {},
      fontFamilyTitle: {},
      fontSize: {},
      position: {},
      subtitle: {},
      showTitle: {},
      title: {
        required
      }
    }
  }
}
