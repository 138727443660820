// Components
import AddonsNeedUpgradeContent from '@/components/elements/addons/AddonsNeedUpgradeContent'
import CardContainer from '@/components/ui/CardContainer'
import FeaturedItemsDetailCTA from '../../components/elements/FeaturedItemsDetailCTA'
import FeaturedItemsDetailDesign from '../../components/elements/FeaturedItemsDetailDesign'
import FeaturedItemsDetailOptions from '../../components/elements/FeaturedItemsDetailOptions'
import FeaturedItemsPreview from '../../components/ui/FeaturedItemsPreview'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Vuex
import { mapState } from 'vuex'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Utils
import { get } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'FeaturedItemsDetail',
  components: {
    AddonsNeedUpgradeContent,
    CardContainer,
    FeaturedItemsDetailCTA,
    FeaturedItemsDetailDesign,
    FeaturedItemsDetailOptions,
    FeaturedItemsPreview,
    VuetifyToolBar
  },
  mixins: [addonsMixin],
  data() {
    return {
      id: get(this.$route, 'params.id', null),
      isIntersecting: false,
      panel: [0], // Indica que paneles están abiertos
      previewOptions: {} // Opciones de la previsualización
    }
  },
  computed: {
    ...mapState('app', ['smallDevice', 'extraSmallDevice'])
  },
  methods: {
    /**
     * Actualiza el UID del documento (featuredItem)
     *
     * @param {string} id - Identificador del modelo del "featuredItem"
     */
    handleIdValue(id) {
      this.id = id
    },
    /**
     * Evento lanzado cuando los elementos "interseccionados",
     * aparecen o desaparecen del "pointview"
     *
     * @param {*} entries - elementos "interseccionados"
     */
    handleIntersect(entries) {
      this.isIntersecting =
        !this.smallDevice && !this.extraSmallDevice && !entries[0].isIntersecting
    },
    /**
     * Actualiza las opciones de previsualización
     *
     * @param {object} data - datos a almacenar en el objeto "previewOptions"
     */
    handlePreviewOptionsValue(data) {
      this.previewOptions = { ...this.previewOptions, ...data }
    },
    /**
     * Mostramos panel "cta"
     */
    showCTAPanel() {
      return getEnvironmentVariable('VUE_APP_BRAND') !== 'schweppes'
    }
  }
}
