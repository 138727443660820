export const FEATURED_ITEMS_WHEN_SHOW = {
  always: {
    id: 'always',
    label: 'Siempre'
  },
  qr: {
    id: 'qr',
    label: 'Desde el QR'
  },
  url: {
    id: 'url',
    label: 'Desde la URL'
  }
}
