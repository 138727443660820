<template>
  <div class="color-selector">
    <div class="d-inline-flex content-preview-sample">
      <!-- Etiqueta -->
      <div v-if="label" class="pt-2 body-2 mr-2 primary--text">
        {{ label }}
      </div>
      <v-menu
        v-model="colorPicker"
        :close-on-content-click="false"
        transition="scale-transition"
        nudge-left
        min-width="auto"
        max-width="250px"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- Ejemplo color -->
          <div class="wrapper-color-sample">
            <div
              class="color-sample"
              :style="`background-color:${selectedCurrentColor}`"
              v-bind="attrs"
              v-on="on"
              @click="colorPicker = !colorPicker"
            />
          </div>
        </template>
        <!-- Selector -->
        <div class="wrapper-color-picker">
          <v-color-picker
            v-if="colorPicker"
            v-bind="colorPickerVOptions"
            :value="color"
            @update:color="handleUpdateColor"
          />
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorSelector',
  model: {
    prop: 'color',
    event: 'update'
  },
  props: {
    label: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: '#555'
    },
    colorPickerVOptions: {
      default() {
        return {
          'hide-inputs': true,
          'hide-canvas': true,
          'show-swatches': true
        }
      },
      type: Object
    }
  },
  data() {
    return {
      colorPicker: false,
      currentColor: this.color
    }
  },
  computed: {
    /**
     * Color actual seleccionado
     *
     * @return {string}
     */
    selectedCurrentColor() {
      return this.currentColor
    }
  },
  watch: {
    color(value) {
      this.currentColor = value
    }
  },
  methods: {
    /**
     * Manejador del cambio de valor
     *
     * @param {Object} event
     */
    handleUpdateColor(event) {
      const { hex } = event
      this.$emit('update', hex)
    }
  }
}
</script>

<style lang="scss" scoped>
.color-selector {
  max-width: 300px;
  .content-preview-sample {
    justify-content: space-between;
    width: 100%;
  }
  .wrapper-color-sample {
    padding: 2px;
    border: 1px solid $theme_border_color;
    .color-sample {
      width: 120px;
      height: 40px;
      cursor: pointer;
    }
  }
}
@media (max-width: 600px) {
  .color-selector {
    max-width: 100%;
  }
}
</style>
