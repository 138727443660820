// Components
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import formMixin from '@/mixins/formMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, url } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters } from 'vuex'
// Services
import {
  createCTA,
  getCTAByFeaturedItemId,
  getCTAFormFields,
  updateCTAById
} from '@/addons/cta/services/cta'
// Utils
import { isNil, get } from 'lodash'

export default {
  name: 'FeaturedItemsDetailCTA',
  components: {
    CardContainerElement,
    FormButtons,
    VuetifyContentLoading
  },
  mixins: [formMixin, validationMixin, uiMixin],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // Form
      formFields: {
        fields: ['email'],
        // generateQr: false,
        labelActionButton: 'Saber más',
        name: null,
        url: null
      },
      formFieldsValidations: {
        fields: {
          required: 'Campo obligatorio'
        },
        name: {
          required: 'Campo obligatorio'
        },
        labelActionButton: {
          required: 'Campo obligatorio'
        },
        url: {
          required: 'Campo obligatorio',
          url: 'Formato incorrecto'
        }
      },
      // Others
      ctaData: null,
      ctaFormFields: [],
      ctaTypeSelected: 'url',
      ctaTypes: {
        fields: {
          id: 'fields',
          label: 'Obtener datos a través de un formulario'
        },
        url: {
          id: 'url',
          label: 'Redirigir a URL'
        }
      },
      processingRequest: false,
      showCTAForm: false
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * Devuelve si el tipo de "CTA" es "fields"
     *
     * @return {boolean}
     */
    isCtaTypeFields() {
      return this.ctaTypeSelected === this.ctaTypes.fields.id
    },
    /**
     * Devuelve si el tipo de "CTA" es "url"
     *
     * @return {boolean}
     */
    isCtaTypeUrl() {
      return this.ctaTypeSelected === this.ctaTypes.url.id
    }
  },
  watch: {
    ctaTypeSelected(value) {
      if (
        value === this.ctaTypes.fields.id &&
        (isNil(value) || (Array.isArray(value) && value.length === 0))
      ) {
        // colocamos los valores por defecto
        // del campo "fields"
        this.formFields.fields = ['email']
      }
    },
    formFields: {
      handler() {
        if (this.showCTAForm) {
          // Emitimos los valores modificados
          this.handleEmitFormValues()
        }
      },
      immediate: true,
      deep: true
    },
    showCTAForm: {
      handler(value) {
        if (value) {
          // Emitimos los valores modificados
          this.handleEmitFormValues()
        }
      },
      immediate: true,
      deep: true
    }
  },
  async mounted() {
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Emitimos los valores de los campos del formulario
     */
    handleEmitFormValues() {
      // Emitimos los valores modificados
      this.$emit('changePreviewOptions', {
        labelActionButton: this.formFields.labelActionButton
      })
    },
    /**
     * Mostramos el formulario de creación de campañas
     */
    handleShowCTAForm() {
      this.showCTAForm = true
    },
    /**
     * Obtenemos/establecemos todos los datos necesarios
     * del componente
     */
    async getEveryNeededData() {
      try {
        // loading
        this.processingRequest = true
        // Establecemos campos a usar en el formulario CTA
        await this.setCtaFormFields()
        // Obtenemos datos del destacado
        this.ctaData = await getCTAByFeaturedItemId(this.id)
        // Establecemos los datos del formulario
        this.setFormFieldsValues(this.ctaData)
        // Mostramos formulario
        this.handleShowCTAForm()
      } catch {
        // TODO - hacer algo si es necesario
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos los campos a seleccionar en el formulario del CTA
     * (establecemos la variable "ctaFormFields")
     */
    async setCtaFormFields() {
      const ctaFormFields = await getCTAFormFields()
      // Incluimos por defecto el campo email
      this.ctaFormFields = ctaFormFields.map((ctaField) => {
        if (ctaField.id === 'email') {
          ctaField.disabled = true
        }

        return ctaField
      })
    },
    /**
     * Inicializamos los campos del formulario
     *
     * @param {object} data - datos del documento obtenido
     */
    async setFormFieldsValues(data) {
      // Selector del tipo de campaña
      if (!isNil(get(data, 'id', null)) && !isNil(get(data, 'url', null))) {
        this.ctaTypeSelected = this.ctaTypes.url.id
      } else if (!isNil(get(data, 'id', null)) && !isNil(get(data, 'fields', null))) {
        this.ctaTypeSelected = this.ctaTypes.fields.id
      }
      // Campos del formulario
      this.formFields = {
        fields: get(data, 'fields', this.formFields.fields),
        // generateQr: get(data, 'generateQr', this.formFields.generateQr),
        labelActionButton: get(data, 'labelActionButton', this.formFields.labelActionButton),
        name: get(data, 'name', this.formFields.name),
        url: get(data, 'url', this.formFields.url)
      }
    },
    /**
     * Método lanzado por "formMixin" tras la validación
     * y antes del envío de los datos
     */
    beforeSubmit() {
      // Reseteamos ciertos campos del formulario,
      // para así actulizar estos correctamente en la
      // base de datos
      if (this.ctaTypeSelected === this.ctaTypes.url.id) {
        this.formFields.fields = null
        // this.formFields.generateQr = false
      }

      // Validación de campos de formulario
      if (this.ctaTypeSelected === this.ctaTypes.fields.id) {
        this.formFields.url = null
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      if (isNil(this.ctaData)) {
        // Creamos
        this.ctaData = await createCTA({
          ...this.formFields,
          placeId: this.placeData.id,
          featuredItemId: this.id
        })
      } else {
        // Actualizamos
        await updateCTAById({ ...this.formFields, id: this.ctaData.id })
      }

      // Mostramos mensaje de todo OK
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  // Validations with Vuelidate
  validations() {
    const rules = {
      formFields: {
        name: {
          required
        },
        labelActionButton: {
          required
        }
      }
    }

    // Validación de URL
    if (this.ctaTypeSelected === this.ctaTypes.url.id) {
      rules.formFields.url = {
        required,
        url
      }
    }

    // Validación de campos de formulario
    if (this.ctaTypeSelected === this.ctaTypes.fields.id) {
      rules.formFields.fields = {
        required
      }
      // rules.formFields.generateQr = {}
    }

    return rules
  }
}
